<template>
  <transition name="dialog">
    <yb-modal
      v-bind="$attrs"
      show
      content-classes="text-center p-8"
      data-test-id="load-monitor"
    >
      <yb-loading v-if="loading" />
      <div v-else class="flex flex-row flex-nowrap mb-8 justify-center relative">
        <yb-sql v-if="sql" :sql="sql" class="absolute -right-4 -top-4" />
        <div class="pl-8">
          <yb-processing v-if="activeLoadResponse == null && !cancelled" class="h-24 w-24" />
          <div v-else-if="loadError" class="w-10 h-10 mt-6">
            <yb-icon class="text-red-700" icon="error" />
          </div>
          <div v-else-if="loadStatistics && loadStatistics.inserted_rows == 0" class="w-12 h-12 mt-4">
            <yb-icon class="text-yb-yellow" icon="info" />
          </div>
          <div v-else class="w-12 h-12 mt-4">
            <yb-icon class="text-yb-green" icon="check" />
          </div>
        </div>
        <div class="flex font-light h-24 ml-1">
          <div v-if="activeLoadResponse == null && !cancelled" class="yb-center text-left">
            Your data is currently loading.
          </div>
          <div v-else-if="loadError" class="yb-center text-left">
            Your load completed in error or was cancelled.
          </div>
          <div v-else-if="loadStatistics && loadStatistics.inserted_rows == 0" class="yb-center text-left">
            Your load completed but did not insert any rows to the table.<br><br>
            Please check your settings and retry.
          </div>
          <div v-else class="yb-center text-left" data-test-id="load-complete">
            Your load is complete.
          </div>
        </div>
      </div>
      <hr>

      <div v-if="haveLoadStatistics && !loading">
        <h5 class="font-light my-8 text-left">
          Load Summary
        </h5>
        <table class="table w-full my-8 text-left text-sm whitespace-nowrap">
          <colgroup>
            <col>
            <col>
            <col>
            <col>
          </colgroup>
          <tbody>
            <tr>
              <td class="font-semibold pr-2">
                Started
              </td><td class="pr-8" colspan="2">
                {{ formatDateTime(loadStatistics.start_time) }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold pr-2">
                Elapsed
              </td><td class="pr-8" colspan="2">
                {{ humanizeMs(loadStatistics.elapsed_ms) }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="h-4" />
              </td>
            </tr>
            <tr>
              <td class="font-semibold pr-4">
                Parse I/O
              </td>
              <td class="pr-2">
                {{ bytes(loadStatistics.parsed_bytes) }}
              </td>
              <td class="pr-2">
                {{ bytes(loadStatistics.parsed_bytes_per_second) }} / second
              </td>
            </tr>
            <tr>
              <td class="font-semibold pr-4">
                Parsed Rows
              </td>
              <td class="pr-2">
                {{ humanizeNumber(loadStatistics.parsed_rows) }} ({{ number(loadStatistics.parsed_rows, 0) }})
              </td>
              <td class="pr-2">
                {{ number(loadStatistics.parsed_rows_per_second) }} / second
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="h-4" />
              </td>
            </tr>
            <tr>
              <td class="font-semibold pr-4">
                Insert I/O
              </td>
              <td class="pr-2">
                {{ bytes(loadStatistics.inserted_bytes) }}
              </td>
              <td class="pr-2">
                {{ bytes(loadStatistics.inserted_bytes_per_second) }} / second
              </td>
            </tr>
            <tr>
              <td class="font-semibold pr-4">
                Inserted Rows
              </td>
              <td class="pr-2">
                {{ humanizeNumber(loadStatistics.inserted_rows) }} ({{ number(loadStatistics.inserted_rows, 0) }})
              </td>
              <td class="pr-2">
                {{ number(loadStatistics.inserted_rows_per_second) }} / second
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="h-4" />
              </td>
            </tr>
            <tr>
              <td class="font-semibold pr-4">
                Transfer I/O
              </td>
              <td class="pr-2">
                {{ bytes(loadStatistics.sent_bytes) }}
              </td>
              <td v-if="loadStatistics.sent_bytes_per_second > 0" class="pr-2">
                {{ bytes(loadStatistics.sent_bytes_per_second) }} / second
              </td>
              <td v-else class="pr-2">
                {{ bytes((loadStatistics.sent_bytes / ((+new Date() - +new Date(loadStatistics.start_time)) / 1000))) }} / second
              </td>
            </tr>
            <tr v-if="loadStatistics.error_rows > 0">
              <td class="font-semibold pr-4 text-red-700">
                Errors
              </td>
              <td class="pr-2">
                {{ humanizeNumber(loadStatistics.error_rows) }} ({{ number(loadStatistics.error_rows, 0) }})
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="(loading && !cancelled) || (activeLoadResponse == null && !haveLoadStatistics)" class="my-8 font-light">
        (Calculating load statistics...)
      </div>
      <div v-else-if="!loading && !haveLoadStatistics && !loadError" class="my-8 font-light">
        (No statistics were generated for this load)
      </div>

      <div class="w-full flex flex-row flex-nowrap justify-center space-x-2">
        <yb-button v-if="activeLoadResponse == null" label="Close" class="yb-button-default-lg" @click="close" />
        <yb-button v-if="activeLoadResponse == null && !cancelled" v-yb-confirm="cancel" label="Cancel" class="yb-button-default-lg" message="Are you sure you want to cancel this load?" />
      </div>

      <div class="relative">
        <yb-clipboard-icon v-if="(activeLoadResponse && activeLoadResponse.results && activeLoadResponse.results.sql_warning_message) || loadError" :value="loadWarningMessage" class="absolute right-0 top-0" label="(load message)" />
        <yb-button
          v-if="(activeLoadResponse && activeLoadResponse.results && !!activeLoadResponse.results.sql_output)"
          v-tooltip="'Download the LOAD TABLE log output'"
          data-test-id="load-log"
          label="Log"
          type="button"
          icon="download"
          class="yb-button-default absolute right-0 bottom-0"
          @click="downloadLog"
        />

        <pre v-if="(activeLoadResponse && activeLoadResponse.results && (activeLoadResponse.results.sql_warning_message)) || loadError" class="font-semibold text-xs whitespace-pre-wrap text-left py-8" :class="{'text-red-700': loadError}">{{ loadWarningMessage }}</pre>
      </div>
    </yb-modal>
  </transition>
</template>

<script>
import FileSaver from 'file-saver'
import { tasksService, jolokiaService, connectionService, mbeans, errorHandler } from '@/services'
import { humanizeMs, humanizeNumber, formatDateTime, number, bytes } from '@/filters'

export default {
  props: {
    database: {
      type: String,
      required: true
    },
    instance: {
      type: Object,
      required: true
    },
    loadTags: {
      type: String,
      required: true
    },
    sql: {
      type: Function,
      required: false
    },
    loadResponse: {
      type: Object,
      required: false
    },
    loadSessionKey: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: true,
      loadStatistics: null,
      loadTimeout: null,
      cancelled: false,
      activeLoadResponse: this.loadResponse
    }
  },
  computed: {
    haveLoadStatistics() {
      return this.loadStatistics && (this.loadStatistics.parsed_bytes > 0 || this.loadStatistics.error_rows > 0)
    },
    loadError() {
      return (!!this.loadResponse?.results?.sql_warning_message && this.loadResponse?.results?.sql_warning_sql_state !== '00000') ||
             (!!this.loadStatistics && !!this.loadStatistics.error_string)
    },
    loadWarningMessage() {
      let warningMessage = this.loadResponse?.results?.sql_warning_message
      if (!warningMessage && !!this.loadStatistics) {
        warningMessage = this.loadStatistics.error_string
      }
      return String(warningMessage || '').trim()
    }
  },
  watch: {
    loadResponse(_) {
      if (_) {
        this.clearTimeout()
        this.loadHistorical()
        this.activeLoadResponse = _
      }
    }
  },
  mounted() {
    // Start grabbing info about this load.
    if (!this.loadResponse) {
      this.loadTimeout = window.setInterval(this.getLoadStatistics.bind(this, 'load'), 1000)
    } else {
      this.loadHistorical()
    }
  },
  unmounted() {
    this.clearTimeout()
  },
  methods: {
    humanizeMs,
    humanizeNumber,
    formatDateTime,
    number,
    bytes,
    clearTimeout() {
      if (this.loadTimeout) {
        window.clearInterval(this.loadTimeout)
        this.loadTimeout = null
      }
    },

    loadHistorical() {
      // Get the final stats.
      window.setTimeout(this.getLoadStatistics.bind(this, 'log_load'), 2000)
    },

    // Grab the data about this load.
    async getLoadStatistics(table) {
      const sql = this.loadSessionKey
        ? `select * from sys.${table} where session_key = '${this.loadSessionKey}'`
        : `select * from sys.${table} where tags = '${this.loadTags}'`
      const connected = await connectionService.connect(this.instance.id)
      const loadResult = await jolokiaService.execute(mbeans.datasources, 'queryEx', [this.database, sql, 'ym', 0])
      this.loading = false
      if (loadResult && loadResult.rows.length === 1) {
        this.loadStatistics = loadResult.rows[0]

        // Is it done?
        if (table === 'load' && (this.loadStatistics.state !== 'RUNNING' && this.loadStatistics.state !== 'STARTING')) {
          this.clearTimeout()
          this.loadHistorical()
          this.activeLoadResponse = this.loadResponse || {}
        }
      }
    },

    async cancel() {
      const query = tasksService.taskState.tasks.find(query => query.tags === this.loadTags)
      if (!query) {
        this.$dialogs.warning('Could not locate query to cancel load')
      } else {
        try {
          await tasksService.cancel(query)
          this.$dialogs.info('Your load was cancelled.')
          this.cancelled = true
          this.clearTimeout()
          this.loadHistorical()
          this.activeLoadResponse = this.loadResponse || {}
        } catch (e) {
          errorHandler.thrown(e, 'Could not cancel load operation.')
        }
      }
    },

    close() {
      this.$emit('close')
    },

    downloadLog() {
      // Save it as a blob.
      const blob = new Blob([this.activeLoadResponse.results.sql_output], { type: 'application/sql;charset=utf-8' })
      let filename = 'load'
      if (this.activeLoadResponse.results.yb_last_execid) {
        filename += `-${this.activeLoadResponse.results.yb_last_execid}`
      }
      filename += '.log'
      FileSaver.saveAs(blob, filename)

      this.$dialogs.info('File saved as ' + filename)
    }
  }
}
</script>
